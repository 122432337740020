@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .fade-in {
    @apply animate-fadeIn;
  }

  .fade-out {
    @apply animate-fadeOut;
  }

  .animate-fadeIn {
    animation: fadeIn 0.3s ease-in-out;
  }

  .animate-fadeOut {
    animation: fadeOut 0.3s ease-in-out;
  }

  .animate-jump {
    animation: jump 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.animate-shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.animate-jump {
    animation: jump 0.5s ease-in-out;
}

.fade-in {
    animation: fadeIn 0.3s ease-in-out;
}}